import { FC, useEffect, useMemo } from "react";
import dayjs from 'dayjs';

import { Box, TableCell, Typography } from "@mui/material";

import { Table as TableType } from "../../../types/components/Table";
import TablePaper from "../../../components/Table/Paper";
import Table from "../../../components/Table";
import TablePagination from "../../../components/Table/Pagination";

import useTableData from "../../../hooks/useTableData";

export interface CheckpointCompletedTableProps {
  data: DataModel.Patrol["checkpoints"];
}

const CheckpointCompletedTable:FC<CheckpointCompletedTableProps> = ({ data }) => {

  const columns = useMemo<TableType.Column<DataModel.Patrol["checkpoints"][0]>[]>(() => {
    return [
      {
        id: "name",
        sortable: true,
        header: "Checkpoint Name",
        accessor: "name",
        width: 80,
      },
      {
        id: "timestamp",
        sortable: true,
        header: "Time Completed",
        accessor: (row) => dayjs.unix(row.timestamp).format("dddd, MMM DD, YYYY hh:mm:ss A"),
        width: 80,
      },
      {
        id: "snapshot",
        sortable: false,
        header: "Snapshot",
        renderBodyCell: ( data, props ) => (
          <TableCell {...props} >
            {
              data.image &&
              <img src={ `data:image/png;base64,${ data.image }` } width="150" />
            }
          </TableCell>
        ),
        width: 80,
      },
      {
        id: "notes",
        sortable: true,
        header: "Notes",
        accessor: "notes",
        width: 80,
      },
      {
        id: "incidents",
        header: "INCIDENTS",
        renderBodyCell: ({ incidents }, cellProps) => (
          <TableCell {...cellProps}>
            <Box>
               { incidents.map((incident: Omit<DataModel.Incident, "uid">, idx: number) => {
                return (
                  <Box key={idx} display="flex">
                    <Typography fontSize={14} lineHeight="17px">
                      <strong>
                        { dayjs.unix( incident.dateTime ).local().format( "hh:mm A" ) }
                      </strong>
                      { " " }
                      { incident.comments }
                    </Typography>
                  </Box>
                );
              }) }
            </Box>
          </TableCell>
        ),
        width: 200,
        tableBodyCellProps: { sx: { verticalAlign: "top" } },
      },
    ];
  }, []);

  const { displayData, setData, paginationProps } =
    useTableData<DataModel.Patrol["checkpoints"][0]>({ columns });

    useEffect(() => {
      setData(data);
    }, [data, setData]);

  return (
    <TablePaper>
      <Box flexGrow={1}>
        <Table dataKey="uid" columns={columns} data={displayData} />
      </Box>

      <TablePagination {...paginationProps} />
    </TablePaper>
  )
}

export default CheckpointCompletedTable;